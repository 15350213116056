<template>
  <VDialog class="gap-2" width="500px">
    <div class="flex flex-col gap-4">
      <VHeading level="4" medium class="text-center" >
        {{ localizeText('shared.download_dialog.title') }}
      </VHeading>
      <VParagraph>{{ localizeText('shared.download_dialog.description') }}</VParagraph>
    
      <div class="flex flex-col gap-2 border border-gray-300  px-4 py-1 max-h-[60vh] overflow-y-auto rounded-md avv-bg">
        <div v-for="document in exportPdfDocuments" :key="document.id" class="flex justify-start items-center gap-2">
          <div class="flex items-center gap-2">
            <VCheckbox v-model="document.checked"/>
          </div>
          <button
            class="text-sm my-1 cursor-pointer truncate"
            :title="document.name"
            @click="handleClick(document)"
          >
            {{ document.name }}
          </button>
        </div>
      </div>
    </div>
    
    <div class="flex justify-end gap-8 mt-4">
      <VButton variant="text" @click="close">
        {{ localizeText('general.cancel') }}
      </VButton>
      <VButton variant="text" @click="download">
        {{ localizeText('general.download') }}
      </VButton>
    </div>
  </VDialog>
</template>

<script setup lang="ts">
import ExportApi from '@api/Documents/ExportApi'
import DriveExportApi from '@api/DriveFiles/ExportApi'
import {ref, computed} from "vue"
import VDialog from "@component-library/dialogs/VDialog.vue"
import VButton from "@component-library/buttons/VButton.vue"
import VHeading from '@component-library/labels/VHeading.vue'
import VParagraph from '@component-library/labels/VParagraph.vue'
import CreatedDialog from "../template_packs/CreatedDialog.vue"
import VCheckbox from "@component-library/inputs/VCheckbox.vue"
import { useDialog } from '@component-utils/dialogs';

type Origin = 'drive' | 'documents' | 'createdDialog'
const props = defineProps<{
  createdDialogData?: {
      name: string,
      link: string, 
      id: number,
      formats: string[]
    }[],
  origin: Origin,
  exportPdfDocuments?: {id: string, name: string, formats: string}[],
  exportDocxDocumentsIds?: string[],
  exportFlags?: string[]

}>()

const localizeText = window.localizeText
const origin = props.origin
const createdDialogData = () => (props.createdDialogData || AvvStore.state.exportableDocuments)

const getDocuments = () => {
  return origin === "createdDialog" ?
    createdDialogData()
      .filter(doc => doc.formats.length === 1 && doc.formats[0] === 'document_pdf')
      .map(doc => ({
        id: doc.id,
        name: doc.name,
        formats: doc.formats,
        checked: false
      }))
    :
    props.exportPdfDocuments?.map(doc => ({
      id: Number(doc.id),
      name: doc.name,
      formats: doc.formats,
      checked: false
    })) || []
}

const exportPdfDocuments = ref(getDocuments())

const exportDocxDocumentsIds = origin !== 'createdDialog'
    ? props.exportDocxDocumentsIds
    : createdDialogData()
        .filter(doc => doc.formats.includes('document_docx'))
        .map(doc => String(doc.id));

const emit = defineEmits<{
  (e: 'close'): void
}>()

const close = () => {
  emit('close')
  if(origin === "createdDialog")
    useDialog(
      CreatedDialog,
      {
        documents_data: props.createdDialogData!
      }
    )
}

const selectedPdfExportsIds = computed(() => exportPdfDocuments.value.filter(doc => doc.checked).map(doc => doc.id))

const download = async () => {
  const allDocumentsIds = [...exportDocxDocumentsIds!, ...selectedPdfExportsIds.value]
  const data: { formats: string, document_ids?: (string | number)[], drive_file_ids?: (string | number)[], flags?: string[] } = {
    formats: "docxorpdf",
    ...(origin === "drive" ? { drive_file_ids: allDocumentsIds } : { document_ids: allDocumentsIds }),
    ... props.exportFlags ? props.exportFlags : []
  }

  await window.avv_download(
    origin === "drive" ? DriveExportApi.create.path() : ExportApi.create.path(),
    data
  )
  close()
}

type Document = {
  id: string
  name: string
  formats: string[]
  checked: boolean
}


const openDocument = (document: Document) => {
  const url = `/documents/${document.id}/continue`
  window.open(url, '_blank')
}

const handleClick = (document: Document) => {
  document.checked = true
  openDocument(document)
}
</script>
